import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub, faInstagram, faNpm, faTwitter, fab } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faBullhorn,
  faChalkboardTeacher,
  faDrawPolygon,
  faEdit,
  faEnvelope,
  faExclamation,
  faLaptopCode,
  faMapMarkerAlt,
  faMobileAlt,
  faPaperPlane,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

/* add any additional icon to the library */
library.add(
  fab,
  faArrowDown,
  faLaptopCode,
  faDrawPolygon,
  faEdit,
  faEdit,
  faBullhorn,
  faMapMarkerAlt,
  faPhone,
  faMobileAlt,
  faPaperPlane,
  faChalkboardTeacher,
  faGithub,
  faTwitter,
  faInstagram,
  faNpm,
  faEnvelope,
  faExclamation,
);

export type IconProps = FontAwesomeIconProps['icon'];

const Icon: React.FC<FontAwesomeIconProps> = ({ ...props }) => <FontAwesomeIcon {...props} />;

export default Icon;
