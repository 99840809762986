import React from 'react';
import Icon from '../Icon';

import * as Styled from './styles';

const Socials: React.FC = () => {
  return (
    <>
      <Styled.Social
        href="https://www.youtube.com/channel/UCLVSWGAETWCB04EyjeUJCzw"
        rel="noreferrer noopener"
        target="_blank"
        title="Koenn Becker's YouTube page">
        <Icon icon={['fab', 'youtube']} size="2x" />
      </Styled.Social>
      <Styled.Social
        href="https://github.com/koennjb"
        rel="noreferrer noopener"
        target="_blank"
        title="A link to Koenn Becker's GitHub account">
        <Icon icon={['fab', 'github']} size="2x" />
      </Styled.Social>
      <Styled.Social
        href="https://www.linkedin.com/in/koenn-becker/"
        rel="noreferrer noopener"
        target="_blank"
        title="A link to Koenn Becker on LinkedIn">
        <Icon icon={['fab', 'linkedin']} size="2x" />
      </Styled.Social>
      <Styled.Social
        href="https://www.instagram.com/koennbecker/"
        rel="noreferrer noopener"
        target="_blank"
        title="A link to Koenn Becker on Instagram">
        <Icon icon={['fab', 'instagram']} size="2x" />
      </Styled.Social>
      <Styled.Social
        href="https://www.npmjs.com/~koennjb"
        rel="noreferrer noopener"
        target="_blank"
        title="A link to Koenn Becker's published packages on NPM">
        <Icon icon={['fab', 'npm']} size="2x" />
      </Styled.Social>
    </>
  );
};

export default Socials;
