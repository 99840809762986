import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

type Meta =
  | {
      name: string;
      content: any;
    }
  | {
      property: string;
      content: any;
    };

interface Props {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title: string;
  image?: Image;
  pathname?: string;
  keywords?: string[];
  article?: boolean;
}

interface Site {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      keywords: string[];
      siteUrl: string;
    };
  };
}

interface Image {
  src: string;
  height: number;
  width: number;
}

const SEO: React.FC<Props> = ({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  keywords,
  article,
}) => {
  const { site } = useStaticQuery<Site>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const shortTitle = site.siteMetadata.title;
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : undefined;
  const image =
    metaImage && metaImage.src ? `${site.siteMetadata.siteUrl}${metaImage.src}` : undefined;
  const imageMeta: Meta[] = metaImage
    ? [
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: metaImage.width,
        },
        {
          property: 'og:image:height',
          content: metaImage.height,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ]
    : [
        {
          name: 'twitter:card',
          content: 'summary',
        },
      ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${shortTitle}`}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `${article ? 'article' : 'website'}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords
            ? [
                {
                  name: 'keywords',
                  content: keywords.join(','),
                },
              ]
            : [],
        )
        .concat(imageMeta)
        .concat(meta!)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [] as Meta[],
  description: ``,
};

export default SEO;
