import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { Link } from 'gatsby';

export const Logo = styled(Link)`
  color: ${(p) => p.theme.colors.text};
  &:hover {
    color: ${(p) => p.theme.colors.text};
  }
  ${tw`flex items-center mr-auto`};
`;

export const Text = styled.h1`
  ${tw`text-2xl font-semibold`};
`;

export const Image = styled.figure`
  border-color: ${(p) => p.theme.colors.primaryColor};
  ${tw`w-16 h-16 mr-3 border rounded-full`};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;
