import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

interface StyledProps {
  open: boolean;
}

export const MainNav = styled.nav<StyledProps>`
  ${tw`sm:flex flex-col sm:flex-row sm:w-auto w-full order-last sm:order-none my-4 sm:my-0 hidden`};
  ${({ open }) => open && tw`flex`};
`;

export const MainNavItem = motion.custom(styled(Link)`
  color: ${(p) => p.theme.colors.text};
  &:hover {
    color: ${(p) => p.theme.colors.text};
  }
  ${tw`relative border-b border-transparent ml-0 sm:ml-8 mt-3 sm:mt-0`};
  width: max-content;

  &.active {
    border-color: ${(p) => p.theme.colors.primaryColor};
  }

  &:before {
    background-color: ${(p) => p.theme.colors.primaryColor};
    ${tw`absolute w-full h-px left-0 invisible`};
    content: '';
    bottom: -1px;
    transform: scaleX(0);
    transition: 0.2s;
  }

  &:hover:before {
    ${tw`visible`};
    transform: scaleX(1);
  }
`);

export const ToogleMainNav = styled.button<StyledProps>`
  ${tw`flex flex-col items-end justify-center cursor-pointer w-6 h-7 sm:hidden`};
  outline: none !important;

  span {
    background-color: ${(p) => p.theme.colors.secondaryColor};
    ${tw`inline-block w-6`};
    height: 2px;
    transition: 0.2s;

    &:first-child {
      ${({ open }) => (open ? 'margin-bottom: -2px' : tw`mb-2`)};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'none')};
    }

    &:last-child {
      ${({ open }) => (open ? 'margin-top: -2px' : tw`mt-2`)};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'none')};
    }

    &:nth-child(2) {
      background-color: ${(p) => p.theme.colors.primaryColor};
      ${tw`inline-block w-8`};
      height: 2px;

      ${({ open }) => (open ? tw`opacity-0` : tw`opacity-1`)};
      transform: ${({ open }) => (open ? 'translate(20px)' : 'none')};
    }
  }
`;
