import React from 'react';

import Container from 'components/ui/Container';

import * as Styled from './styles';
import Socials from 'components/ui/Socials';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gatsby-icon.png" }, sourceInstanceName: { eq: "images" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 20, height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Styled.Footer>
      <Container
        className="justify-center
    ">
        <Styled.Links>
          <Socials />
        </Styled.Links>
        <Styled.Copyright>
          Copyright © 2021 Koenn Becker.
          <br />
          Built using{' '}
          <a href="https://www.gatsbyjs.com/" rel="noreferrer" target="_blank">
            Gatsby
          </a>
          <Img fixed={data.file.childImageSharp.fixed} alt="Gatsby logo" />
          <br />
          <a
            href="https://www.gatsbyjs.com/starters/SaimirKapaj/gatsby-markdown-typescript-personal-website"
            rel="noreferrer"
            target="_blank">
            Using starter 🎨
          </a>
        </Styled.Copyright>
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
