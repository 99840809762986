import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primaryColor: string;
      secondaryColor: string;
      accentColor: string;
      background: string;
      text: string;
      link: string;

      navbarBackground: string;
      sidebarBackground: string;

      surface: string;
      surfaceMedium: string;
      surfaceDark: string;

      softText: string;

      errorColor: string;
    };
    measurements: {
      navbarHeight: string;
    };
  }
}

export const NormalTheme: DefaultTheme = {
  colors: {
    primaryColor: '#81e6d9',
    secondaryColor: '#5a67d8',
    accentColor: '#c3dafe',
    background: '#574ae2',
    text: '#3c366b',
    link: '#5a67d8',

    surface: '#F7FAFC',

    navbarBackground: '#101f37',
    sidebarBackground: '#101f37',

    surfaceMedium: '#EBE9E9',
    surfaceDark: '#CCCCCC',
    softText: '#979797',

    errorColor: '#f74747',
  },
  measurements: {
    navbarHeight: '70px',
  },
};
