import styled from 'styled-components/macro';
import { darken } from 'polished';
import tw from 'tailwind.macro';

export const Footer = styled.footer`
  background-color: ${(p) => p.theme.colors.surface};
  border-color: ${(p) => darken(0.05, p.theme.colors.surface)};
  ${tw` border-t  py-4`};
`;

export const Copyright = styled.p`
  ${tw`mt-4 text-center`}
  .gatsby-image-wrapper {
    ${tw`ml-1 align-text-top`}
  }
`;

export const Links = styled.div`
  ${tw`flex items-center justify-center w-full`};

  a {
    color: ${(p) => p.theme.colors.text};
    ${tw`mx-4`};
  }
  a:hover {
    color: ${(p) => p.theme.colors.link};
  }
`;
