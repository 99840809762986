import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

export interface StyledProps {
  section?: boolean;
  prose?: boolean;
}

export const Container = styled.div<StyledProps>`
  ${tw`flex flex-wrap max-w-screen-md w-full mx-auto p-5`};
  ${({ section }) => section && tw`py-8 sm:py-16`};
  max-width: ${(props) => props.prose && '65ch'};
`;

export const WideContainer = styled.div<StyledProps>`
  ${tw`flex flex-wrap w-full mx-auto p-5`};
  ${(props) => (props.prose ? tw`max-w-prose` : tw`max-w-6xl`)};
`;
