import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';

import * as Themes from '../../theme';

import Header from 'components/Header';
import Footer from 'components/Footer';

import GlobalStyles from 'assets/styles/globalStyles';
import * as Styled from './styles';
import { ThemeProvider } from 'styled-components/macro';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <ThemeProvider theme={Themes.NormalTheme}>
        <GlobalStyles />
        <AnimatePresence exitBeforeEnter>
          <Styled.Layout>
            <Header siteTitle={data.site.siteMetadata.title} />
            <motion.div
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.2 }}>
              {children}
              {/* <Newsletter /> */}
              <Footer />
            </motion.div>
          </Styled.Layout>
        </AnimatePresence>
      </ThemeProvider>
    </>
  );
};

export default Layout;
