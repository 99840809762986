import React from 'react';

import * as Styled from './styles';

interface Props extends Styled.StyledProps {
  children: React.ReactNode;
  className?: string;
  wide?: boolean;
}

const Container: React.FC<Props> = ({ className, wide, section, children, prose }) => {
  return wide ? (
    <Styled.WideContainer section={section} prose={prose} className={className}>
      {children}
    </Styled.WideContainer>
  ) : (
    <Styled.Container section={section} prose={prose} className={className}>
      {children}
    </Styled.Container>
  );
};

export default Container;
