import { createGlobalStyle } from 'styled-components/macro';
import { darken, lighten } from 'polished';
import tw from 'tailwind.macro';

export default createGlobalStyle`
  html {
    font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  }
  body {
    color: ${(p) => p.theme.colors.text};
    ${tw`m-0 bg-white`};
  }

  a {
    color: ${(p) => p.theme.colors.link};
  }
  a:hover {
    color: ${(p) => darken(0.1, p.theme.colors.link)};
  }

  p + p {
    ${tw`mt-3`};
  }

  .prose {
    ${tw`w-full`};
  }

  .gatsby-code-button-container {
    
  }
  .gatsby-code-button {
    color: #fff;
    background: #1e1e35;
    border-radius: 4px 4px 0px 4px;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    top: 0px;
    opacity: 0.5;
    transition: 0.2s;
  }
  .gatsby-code-button:hover {
    opacity: 1;
  }
  .gatsby-code-button[data-tooltip=""]:hover:after {
    display: none;
  }
  .gatsby-code-button:after {
    display: none;
  }
  .gatsby-code-button-icon {
    display: none;
  }
  .gatsby-code-button-toaster {

  }
  .gatsby-code-button-toaster-text {
    ${tw`rounded-lg absolute bottom-4 right-4 w-48 bg-indigo-100 text-indigo-900 font-bold border-indigo-200 border`};
    font-family: inherit;
    letter-spacing: 0.075rem;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
`;
