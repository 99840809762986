import styled from 'styled-components/macro';
import tw from 'tailwind.macro';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export const Social = styled(OutboundLink)`
  color: ${(p) => p.theme.colors.text};
  ${tw`relative border-b border-transparent mx-2`};
  width: max-content;

  &:hover {
    color: ${(p) => p.theme.colors.link};
  }

  &:before {
    background-color: ${(p) => p.theme.colors.primaryColor};
    ${tw`absolute w-full left-0 invisible`};
    height: 2px;
    content: '';
    bottom: -5px;
    transform: scaleX(0);
    transition: 0.2s;
  }

  &:hover:before {
    ${tw`visible`};
    transform: scaleX(1);
  }
`;
